<!--  -->
<template>
  <div style="background:rgba(190,190, 190, 0.2);height:300vh;">
    <van-sticky >
     <!-- 盘点类型 -->
     <div class="point_top" style=" display: flex;
  justify-content: center;
  align-items: center;">
        <!-- <i class="el-icon-arrow-left" @click="back()"></i> -->
        <!-- <div class="point_kind">{{ pointTitle.inventoryTypeName }}</div> -->
        <div class="point_kind">采价报表</div>
        <!-- <div class="point_point">…</div> -->
      </div>
    </van-sticky>
    <!-- 时间筛选 -->
    <div style="width:92%;height:30vw;margin-left:2%;padding:0 2vw;background: #fff;border-radius: 2vw;margin-top: 2vw;" >
          <div class="every_option">
              <div style="display:flex;">
                <div style="font-size:3.4vw;margin-left:6vw;">开始时间</div>
              </div>
              <div style="width:30vw;height:100%;display:flex;align-items:center;" @click="chooseDate(1)">
                <div style="font-size:3.4vw;margin-left:7vw;" >{{query.startTime?query.startTime:'点击选择'}}</div>
              </div>
          </div>
          <div  class="every_option">
            <div style="display:flex;">
              <div style="font-size:3.4vw;margin-left:6vw;">结束时间</div>
            </div>
            <div style="width:30vw;height:100%;display:flex;align-items:center;" @click="chooseDate(2)">
              <div style="font-size:3.4vw;margin-left:7vw;" >{{query.endTime?query.endTime:'点击选择'}}</div>
            </div>
          </div>
        </div>
    <!-- c采价报表 -->
    <div style="width:92%;height:22vw;background:white;border-radius:2vw;margin-left:2%;margin-top:2vw;display: flex;justify-content:space-around;flex-direction: column;padding:0 2vw">
        <div style="display:flex;padding:2vw 0" >
            <img src="../assets/add/drug-full.png" alt="" style="width:5vw;height:5vw;padding-left: 3vw;">
            <input type="text" placeholder="药品搜索：" style="border:0;background:white ;margin-left: 2vw;" v-model="medicinal" >
        </div>
        <div style="width:85vw;border-top:1px dashed rgba(0,0,0,0.3);margin-left: 3vw;"></div>
        <div style="display:flex;padding:2vw 0" >
            <img src="../assets/add/xingming (1).png" alt="" style="width:5vw;height:5vw;padding-left: 3vw;">
            <input type="text" placeholder="填报人：" style="border:0;background:white ;margin-left: 2vw;" v-model="Informant"  >
        </div>
       
    </div>
<div style="display:flex;justify-content:center">    <div style="display:flex;justify-content:center;margin-top:2vw;" @click="watchList()"><van-button style="width:24vw;
 height:9vw;
        border-radius:1vw;
        background: #0aabf4;
        border: 0;
        margin-left:5vw;
        font-size:4vw;color:white">确定查询</van-button></div>
            <div style="display:flex;justify-content:center;margin-top:2vw;" @click="back()"><van-button style="width:24vw;
     height:9vw;
        border-radius:1vw;
        background: #0aabf4;
        border: 0;
        margin-left:5vw;
        font-size:4vw;color:white">返回上级</van-button></div></div>
     <!-- 药品搜索 ---药品详细信息 -->
     <div v-if="caijiaWatcList.length>0">
        <div style="border: 1px solid rgba(190,190, 190, 0.3);background:white;width:94%;margin-left:3%;border-radius:3vw;margin-top: 2vw;height: 20vw;">
           <div style="width:100%;height:50%;background:#4BC4FA;border-radius: 2vw 2vw  0 0 ;display: flex;justify-content: space-around;align-items: center;color: #fff;font-size:3vw;">
            <div>日期</div>
            <div>货号</div>
            <div>品名</div>
            <div>规格</div>
            <div>产地</div>
            <div>询价价格</div>
            <div>店铺</div>
            <div>填报人</div>
        </div>
           <div style="width:100%;height:50%;background:white ;display: flex;justify-content: space-around;align-items: center;font-size:4vw;" v-for="(item,index) in caijiaWatcList" :key="index">
            <div style="margin-left:2vw">
                <el-tooltip placement="top">
              <div slot="content">{{item.inquiryDate}}</div>
              <div style="overflow: hidden; white-space: nowrap; width:6.7vw">
                {{item.inquiryDate}}
              </div>
            </el-tooltip>
            
            </div>
            <div style="margin-right:2vw">
                <el-tooltip placement="top">
              <div slot="content">{{item.goodsNo}}</div>
              <div style="overflow: hidden; white-space: nowrap; width:6.6vw">
                {{item.goodsNo}}
              </div>
            </el-tooltip>
            </div>
            <div style="margin-right:2vw">
                <el-tooltip placement="top">
              <div slot="content">{{item.goodsName}}</div>
              <div style="overflow: hidden; white-space: nowrap; width:8vw">
                {{item.goodsName}}
              </div>
            </el-tooltip>
            </div>
            <div style="margin-right:1vw">
               <el-tooltip placement="top">
              <div slot="content">{{item.specification}}</div>
              <div style="overflow: hidden; white-space: nowrap; width: 8vw">
                {{item.specification}}
              </div>
            </el-tooltip>
            </div>
            <div>
                <el-tooltip placement="top">
              <div slot="content">{{item.productionUnit}}</div>
              <div style="overflow: hidden; white-space: nowrap; width: 8vw">
                {{item.productionUnit}}
              </div>
            </el-tooltip>
            </div>
            <div>
                <el-tooltip placement="top">
              <div slot="content">{{item.inquiryPrice}}</div>
              <div style="overflow: hidden; white-space: nowrap; width: 13vw">
                {{item.inquiryPrice}}
              </div>
            </el-tooltip>
            </div>
            <div style="margin-left:2vw">
                <el-tooltip placement="top">
              <div slot="content">{{item.storeName}}</div>
              <div style="overflow: hidden; white-space: nowrap; width: 10vw">
                {{item.storeName}}
              </div>
            </el-tooltip>
            </div>
            <div style="margin-right:1vw"> <el-tooltip placement="top">
              <div slot="content">{{item.operator}}</div>
              <div style="overflow: hidden; white-space: nowrap; width:8vw">
                {{item.operator}}
              </div>
            </el-tooltip></div>
           </div>
        </div> 
    </div>
    
    
        <!-- 日期选择 -->
        <van-popup v-model="choose_popup" position="bottom" >
            <van-datetime-picker v-model="currentDate" type="date" title="选择年月日"  :max-date="maxDate" @confirm="chooseDateBtn" @cancel="cancelBtn"/>
        </van-popup>
  </div>
</template>

<script>
export default {
  data () {
    return {
        query:{
       
        startTime:'',
        endTime:'',
       
      },
      popup_type:0,//弹出层 1日期筛选 2子表
      choose_type:0,//弹窗状态 1开始时间 2结束时间
      choose_popup:false,
      maxDate: 0,//时间不超过今天
      currentDate: 0,//用户选中的时间
      Informant:"",//填报人
      medicinal:"",//药片搜索
      caijiaWatcList:[],//
    }
  },
  mounted(){
    let new_time = new Date();
      this.maxDate = new_time;
      this.currentDate = new_time;
  },
  methods: {
    watchList(){
      
        console.log(this.Informant,"填报人")
        console.log(this.medicinal,"药品搜索")
        console.log(this.query.startTime,"开始时间")
        console.log(this.query.endTime,"结束时间")
       
        this.$axios.post(this.$api.watchList + "?goodsWord=" + this.medicinal+"&operator="+this.Informant+"&beginTime="+this.query.startTime+"&endTime="+this.query.endTime, {
                // goodsWord:this.medicinal,
                // operator:this.Informant,
                // beginTime:this.query.startTime,
                // endTime:this.query.endTime
            })
            .then((res) => {
            console.log(res,"采价报表")
            this.caijiaWatcList=res.data.data
            console.log(this.caijiaWatcList,"采价报表")
            });

    },
    edit(){
    console.log(111111)
    },
    // 填报人搜索
    InformantSearch(){
          console.log(this.Informant,"aa")
    },
    // 药品搜索
    medicinalSearch(){
       console.log(this.medicinal,"bb")
    },
     //时间格式化
    //  getYYYYMMDD(e){
    //   let nowDate = new Date(e);
		// 	let day = nowDate.getDate();
		// 	let month = nowDate.getMonth() + 1;
		// 	let year = nowDate.getFullYear();
		// 	return  year + '-' + month + '-' + day;
    // },
    cancelBtn(){
      this.choose_popup = false;
    },
    chooseDateBtn(){
      this.choose_popup = false;
      let month=this.currentDate.getMonth() + 1
      let newMonth=month >=10 ? month : "0" + month
      let day=this.currentDate.getDate()
      let newDay=day >=10 ? day : "0" + day
      let tie=`${this.currentDate.getFullYear()}-${newMonth}-${newDay}`;
      console.log(tie)
      console.log(this.currentDate,"时间")

      // let choose_time = this.getYYYYMMDD(this.currentDate)
      if(this.choose_type==1){//选中开始时间
        this.query.startTime = tie;
        console.log(this.query.startTime,"开始时间")
        
      }else{
        this.query.endTime = tie;
        console.log(this.query.endTime,"结束时间")
      }
      this.currentDate = new Date();
    },
 //选择开始时间
 chooseDate(e){
        this.choose_type = e;
        this.choose_popup = true;
        this.popup_type = 1;
    },
    back() {
      this.$router.go(-1);
    //   this.$router.push('/point');
    },
  }
}

</script>
<style lang='less' scoped>
</style>
<style>
.point_top {
  width: 100%;
  height: 6vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  color: #ffffff;
  font-size: 4.6vw;
 
}
.point_point {
  font-size: 6vw;
  font-weight: bolder;
  margin-right: 3vw;
}
.point_top i {
  width: 25px;
  height: 20px;
  margin-left: 3vw;
  font-weight:600;
}
.every_option{
  height:7vh;
  display:flex;
  justify-content:space-between;
  align-items:center;
  border-bottom: 1px solid #E2E2E2;
}
.every_select{
  display:flex;
  font-size:3.5vw;
  flex-wrap: nowrap;
 
}
</style>