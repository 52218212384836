import { render, staticRenderFns } from "./priceCollect_table.vue?vue&type=template&id=0ef1df56&scoped=true&"
import script from "./priceCollect_table.vue?vue&type=script&lang=js&"
export * from "./priceCollect_table.vue?vue&type=script&lang=js&"
import style1 from "./priceCollect_table.vue?vue&type=style&index=1&id=0ef1df56&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef1df56",
  null
  
)

export default component.exports